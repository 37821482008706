import React from 'react'
import Content from '../Content'

const ContentSlice = ({ sliceData }) => {
  return (
    <Content
      heading={sliceData.primary.heading}
      text={sliceData.primary.rich_text.html}
      button={sliceData.primary.button}
      buttonText={sliceData.primary.button_text}
      buttonColour={sliceData.primary.button_colour}
    />
  )
}

export default ContentSlice
