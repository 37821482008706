import React from 'react'

class AccordionSlice extends React.Component {
  handleClick = index => {
    if (index === this.state.activeItem) {
      this.setState({ activeItem: '' })
    } else {
      this.setState({ activeItem: index })
    }
  }
  state = { activeItem: 0 }
  render() {
    const { sliceData } = this.props

    return (
      <section className="py-6 md:py-10">
        {sliceData.primary.accordion_heading && (
          <div className="container mb-10 md:mb-16">
            <div className="max-w-4xl mx-auto">
              <h2 className="font-semibold text-xl lg:text-3xl xl:text-4xl mb-6">
                {sliceData.primary.accordion_heading}
              </h2>
              <div className="dash"></div>
            </div>
          </div>
        )}

        {sliceData.items && (
          <div className="container">
            <div className="max-w-4xl mx-auto">
              {sliceData.items.map((item, index) => (
                <div key={index} className="mb-8 md:mb-12">
                  <button
                    className={`w-full text-left focus:outline-none cursor-pointer pointer-events-auto hover:text-teal transition-color ${
                      this.state.activeItem === index ? 'text-teal' : ''
                    }`}
                    onClick={() => {
                      this.handleClick(index)
                    }}
                  >
                    <h2 className="block relative pr-14 xl:pr-20 text-lg lg:text-xl font-semibold">
                      {item.accordion_item_title}
                      <svg
                        className={`absolute right-0 top-0 h-8 w-8 lg:h-10 lg:w-10 lg:-mt-1 text-teal fill-current transition-transform ${
                          this.state.activeItem === index ? 'rotate-180' : ''
                        } `}
                        viewBox="0 0 45.72 46.53"
                      >
                        <path d="M22.86,30.33,13,20.5l1.35-1.34,8.48,8.47,8.48-8.47,1.35,1.34Zm22.86-7.07A23.09,23.09,0,0,0,22.86,0,23.1,23.1,0,0,0,0,23.26,23.1,23.1,0,0,0,22.86,46.53,23.09,23.09,0,0,0,45.72,23.26Zm-1.9,0a21.18,21.18,0,0,1-21,21.36A21.18,21.18,0,0,1,1.91,23.26a21.18,21.18,0,0,1,21-21.35A21.18,21.18,0,0,1,43.82,23.26Z" />
                      </svg>
                    </h2>
                  </button>
                  {this.state.activeItem === index && (
                    <div className="rte text-sm md:text-base md:mt-6 pr-14 xl:pr-20">
                      {item.accordion_item_content.html && (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.accordion_item_content.html,
                            }}
                          ></div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    )
  }
}

export default AccordionSlice
