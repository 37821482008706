import React from 'react'

import Image from 'components/Image'
import ModalVideo from 'react-modal-video'

class LargeImageSlice extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  closeModal() {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <section className="mb-1">
        <div
          className={
            this.props.sliceData.primary.image_width === 'Full-width'
              ? ''
              : 'container'
          }
        >
          {(this.props.sliceData.primary.image.localFile ||
            this.props.sliceData.primary.image.url) && (
            <div className="relative text-center z-10">
              {this.props.sliceData.primary.modal_video_id && (
                <>
                  <button
                    aria-label="Play"
                    className="video-play absolute text-white hover:text-blue transition-color m-auto outline-none focus:outline-none z-50"
                    onClick={this.openModal}
                  >
                    <svg
                      viewBox="0 0 100 100"
                      className="w-10 md:w-16 lg:w-24 h-10 md:h-16 lg:h-24 inline-block fill-current"
                    >
                      <path d="M50,0A50,50,0,1,1,0,50,50,50,0,0,1,50,0Zm0,4A46,46,0,1,0,96,50,46,46,0,0,0,50,4ZM43,28,65,50,43,72Z" />
                    </svg>
                  </button>
                </>
              )}
              {this.props.sliceData.primary.cover_video_direct_link && (
                <video
                  className="w-full h-full object-cover"
                  muted
                  autoPlay
                  loop
                  playsInline
                  src={this.props.sliceData.primary.cover_video_direct_link}
                ></video>
              )}
              {!this.props.sliceData.primary.cover_video_direct_link &&
                (this.props.sliceData.primary.image.localFile ||
                  this.props.sliceData.primary.image.url) && (
                  <Image
                    fluid={
                      this.props.sliceData.primary.image.localFile
                        ?.childImageSharp?.fluid
                    }
                    src={this.props.sliceData.primary.image.url}
                    className="w-full"
                    alt={this.props.sliceData.primary.image.alt}
                  />
                )}
            </div>
          )}
        </div>
        {this.props.sliceData.primary.modal_video_id && (
          <ModalVideo
            autoplay={true}
            channel="vimeo"
            className="px-4"
            isOpen={this.state.isOpen}
            videoId={this.props.sliceData.primary.modal_video_id}
            maxwidth="100%"
            onClose={() => this.closeModal()}
          />
        )}
      </section>
    )
  }
}

export default LargeImageSlice
