import React from 'react'
import Image from 'components/Image'
import Link from 'components/Link'

const LinkPanel = props => {
  const { link, img, alt, title } = props

  return (
    <Link
      link={link}
      className="w-full md:w-1/2 lg:w-1/3 pl-1 pb-1 relative link-panel"
    >
      <div className="ratio ratio--3/5">
        <div className="ratio__content bg-teal">
          {img && (img.localFile || img.url) && (
            <Image
              className="w-full h-full"
              src={img.url}
              fluid={img.localFile?.childImageSharp?.fluid}
              alt={alt}
            />
          )}
          {title && (
            <span className="absolute left-0 bottom-0 mb-8 md:mb-12 xl:mb-14 pb-1 px-5 md:px-6 lg:px-8 xl:px-10 bg-teal-opaque-75 text-xl md:text-2xl xl:text-4xl font-semibold text-white">
              {title}.
            </span>
          )}
        </div>
      </div>
    </Link>
  )
}

export default LinkPanel
