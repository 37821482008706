import React from 'react'

import ContentAppInformation from '../../components/Content/ContentAppInformation'

const AppInformationSlice = ({ index, sliceData }) => (
  <ContentAppInformation
    heading={sliceData.primary.heading}
    text={sliceData.primary.rich_text}
    button={sliceData.primary.button}
    buttonText={sliceData.primary.button_text}
    image={sliceData.primary.image}
    features={sliceData.items}
    layout={index % 2 === 0 ? '' : 'alternative'}
  />
)

export default AppInformationSlice
