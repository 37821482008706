import React from 'react'
import PropTypes from 'prop-types'
import { mapStyles } from '../../data/mapStyles.js'
import mapMarkerIcon from '../../images/map-marker-icon.svg'

class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
  }

  state = {
    defaultLocation: this.props.locations[0],
    activeLocation: 0,
  }

  createMap() {
    const { defaultLocation } = this.state
    return new window.google.maps.Map(this.mapRef.current, {
      zoom: 16,
      center: {
        lat: defaultLocation.location.latitude,
        lng: defaultLocation.location.longitude,
      },
      disableDefaultUI: true,
      styles: mapStyles,
    })
  }

  createMarkers() {
    let markers = []
    this.props.locations.forEach(item => {
      const marker = new window.google.maps.Marker({
        position: { lat: item.location.latitude, lng: item.location.longitude },
        map: this.googleMap,
        icon: mapMarkerIcon,
      })

      const content = this.props.getMarkerInfo
        ? this.props.getMarkerInfo(item)
        : ``

      if (content.length) {
        const infowindow = new window.google.maps.InfoWindow({ content })
        marker.addListener('click', () => {
          infowindow.open(this.googleMap, marker)
        })
      }

      markers.push(marker)
    })
    return markers
  }

  setLocation(index, latitude, longitude) {
    this.setState({
      activeLocation: index,
    })
    this.googleMap.panTo(new window.google.maps.LatLng(latitude, longitude))
  }

  componentDidMount() {
    const googleMapScript = document.createElement('script')
    const googleMapApiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}`
    window.document.body.appendChild(googleMapScript)
    googleMapScript.addEventListener('load', () => {
      this.googleMap = this.createMap()
      this.googleMarker = this.createMarkers()
    })
  }

  render() {
    return <div ref={this.mapRef} className="google-map w-full h-full" />
  }
}

GoogleMap.propTypes = {
  getMarkerInfo: PropTypes.func,
}

export default GoogleMap
