import React from 'react'

import ContentWithImage from '../../components/Content/ContentWithImage'

const ImageContentSlice = ({ index, sliceData }) => (
  <ContentWithImage
    heading={sliceData.primary.heading}
    text={sliceData.primary.rich_text}
    button={sliceData.primary.button}
    buttonText={sliceData.primary.button_text1}
    buttonColour={sliceData.primary.button_colour}
    image={sliceData.primary.image}
    callOut={sliceData.primary.image_overlay_text}
    layout={index % 2 === 0 ? '' : 'alternative'}
  />
)

export default ImageContentSlice

// Video URL: {sliceData.primary.video_url && sliceData.primary.video_url}
