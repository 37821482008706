import React from 'react'
import GoogleMap from '../GoogleMap'

const LocationsSlice = ({ sliceData }) => {
  let locations = []
  for (const location in sliceData.items) {
    if (sliceData.items.hasOwnProperty(location)) {
      const { latitude, longitude } = sliceData.items[
        location
      ].activity_location
      locations.push({
        title: sliceData.items[location].activity_name,
        description: sliceData.items[location].activity_description,
        link: sliceData.items[location].activity_link,
        location: {
          latitude,
          longitude,
        },
      })
    }
  }

  /**
   * Callback passed to the map component that generates marker text content
   * @author Josh Smith <josh@batch.nz>
   * @param  {object} item Location item object
   * @return {string}      Content to go in the marker info window
   */
  const getMarkerInfo = item => {
    const infoTitle = item.title && `<h1>${item.title}</h1>`
    const infoDescription = item.description && `<p>${item.description}</p>`
    const infoLink =
      item.link?.url &&
      `<a href="${item.link.url}" ${
        item.link?.target ? `target="${item.link.target}"` : ``
      }>Click here to visit website</a>`

    let markerInfo = `${infoTitle || ``}${infoDescription || ``}${infoLink ||
      ``}`

    if (markerInfo.length) {
      markerInfo = `<div class="google-map__marker-info">${markerInfo}</div>`
    }

    return markerInfo
  }

  return (
    <div className="relative h-128 md:h-148 xl:h-195 mb-1">
      <div className="absolute inset-0 z-20 pointer-events-none">
        <div className="container h-full flex items-start lg:items-center">
          <div className="w-full lg:w-1/3 bg-white max-w-lg xl:max-w-full shadow-md pointer-events-auto p-6 pt-5 lg:p-12 lg:pt-10 mt-8 lg:mt-0">
            {sliceData.primary.title1 && (
              <>
                <h3 className="font-semibold text-xl xl:text-3xl mb-6 leading-tight">
                  {sliceData.primary.title1}
                </h3>
                <div className="dash mb-6"></div>
              </>
            )}
            {sliceData.primary.description && (
              <div className="2xl:w-4/5">
                <p className="font-semibold text-lg leading-tight md:leading-normal xl:text-2xl">
                  {sliceData.primary.description}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative w-full h-full z-10">
        {locations && (
          <GoogleMap locations={locations} getMarkerInfo={getMarkerInfo} />
        )}
      </div>
    </div>
  )
}

export default LocationsSlice
