import React from 'react'
import Image from 'components/Image'

const TwoImageSlice = ({ sliceData }) => (
  <section className="my-6 md:my-10 lg:my-12 xl:my-18">
    <div className="container">
      <div className="flex flex-wrap -ml-2">
        <div className="w-full md:w-1/2 pl-2 mb-4 md:mb-0">
          {(sliceData.primary.image_1.localFile ||
            sliceData.primary.image_1.url) && (
            <Image
              src={sliceData.primary.image_1.url}
              fluid={
                sliceData.primary.image_1.localFile?.childImageSharp?.fluid
              }
              alt={sliceData.primary.image_1.alt}
            />
          )}
        </div>
        <div className="w-full md:w-1/2 pl-2">
          {(sliceData.primary.image_2.localFile ||
            sliceData.primary.image_2.url) && (
            <Image
              src={sliceData.primary.image_2.url}
              fluid={
                sliceData.primary.image_2.localFile?.childImageSharp?.fluid
              }
              alt={sliceData.primary.image_2.alt}
            />
          )}
        </div>
      </div>
    </div>
  </section>
)

export default TwoImageSlice
