import React from 'react'
import LinkPanel from 'components/LinkPanel'

const LinkPanelSlice = ({ sliceData }) => (
  <div className="flex flex-wrap -ml-1 -pb-1">
    {sliceData.items &&
      sliceData.items.map((item, index) => (
        <LinkPanel
          key={index}
          link={item.panel_link}
          title={item.panel_title}
          img={item.panel_image}
          alt={item.panel_image.alt}
        />
      ))}
  </div>
)

export default LinkPanelSlice
