import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'components/Image'

import ContentExcerpt from '../Content/ContentExcerpt'
import Link from '../Link'

import GooglePlayBadge from '../../images/google-play-badge.png'
import AppStoreBadge from '../../images/app-store-badge.png'

const AppInformationSlice = ({
  heading,
  text,
  button,
  buttonText,
  image,
  features,
  layout,
}) => {
  const data = useStaticQuery(graphql`
    query {
      prismicGlobals {
        data {
          apple_store_url
          google_play_url
        }
      }
    }
  `)
  return (
    <section className="relative mb-1">
      <div
        className={`lg:absolute lg:w-1/2 inset-y-0 z-10 ${
          layout === 'alternative' ? 'left-0' : 'right-0'
        }`}
      >
        {image && (image.localFile || image.url) && (
          <Image
            className="w-full lg:h-full relative z-10"
            src={image.url}
            fluid={image.localFile?.childImageSharp?.fluid}
            alt={image.alt}
          />
        )}
        {features && features && (
          <div className="hidden lg:flex justify-end absolute inset-0 z-20 pr-5 2xl:pr-8">
            <div className="w-7/12 xl:w-5/12 h-full flex items-center">
              <div className="max-w-2xs">
                {features.map((item, index) => (
                  <div className="flex mb-10 last:mb-0" key={index}>
                    <div className="h-18 w-18 flex items-center justify-center flex-shrink-0 bg-white rounded-full mr-4">
                      {(item.feature_icon.localFile ||
                        item.feature_icon.url) && (
                        <img
                          className="w-10 h-10"
                          src={
                            item.feature_icon.url ||
                            item.feature_icon.localFile.url
                          }
                          alt={item.feature_icon.alt}
                        />
                      )}
                    </div>
                    {item.feature_description && (
                      <h3 className="text-xl font-semibold text-white leading-tight mt-2">
                        {item.feature_description}
                      </h3>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="container relative z-20">
        <div
          className={`flex -ml-8 ${
            layout === 'alternative' ? 'justify-end' : ''
          }`}
        >
          <div
            className={`w-full lg:w-1/2 pl-8 py-10 md:py-20 ${
              layout === 'alternative' ? 'flex lg:justify-end' : ''
            }`}
          >
            <div className="md:w-10/12">
              <ContentExcerpt heading={heading} text={text} />
              <div className="flex flex-wrap items-center max-w-xl mt-6 md:mt-10 -ml-3 -mb-3">
                <div className="pl-3 pb-3">
                  {data.prismicGlobals.data.google_play_url && (
                    <a
                      data-ga-target={'google-play-button'}
                      href={data.prismicGlobals.data.google_play_url}
                      className="block"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-12 w-auto"
                        src={GooglePlayBadge}
                        alt="Get it on Google Play"
                      />
                    </a>
                  )}
                </div>

                <div className="pl-3 pb-3">
                  {data.prismicGlobals.data.apple_store_url && (
                    <a
                      data-ga-target={'app-store-button'}
                      href={data.prismicGlobals.data.apple_store_url}
                      className="block"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="h-12 w-auto"
                        src={AppStoreBadge}
                        alt="Download on the App Store"
                      />
                    </a>
                  )}
                </div>
                <div className="w-full md:w-auto flex-grow pl-3 pb-3">
                  <Link
                    link={button}
                    text={buttonText}
                    className="button-primary w-full h-12"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AppInformationSlice
